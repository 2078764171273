import { UpgradeTierDrawer as UpgradeTierDrawerUI } from '@decub8/ui';

import { useUpgradeTier } from './hooks';

const UpgradeTierDrawer: React.FC = () => {
    const props = useUpgradeTier();

    return <UpgradeTierDrawerUI {...props} />;
};

export default UpgradeTierDrawer;
