import { UpgradeTierStage } from '@decub8/ui';
import { TierColors } from '@decub8/ui/dist/molecules/types';
import { commify } from '@ethersproject/units';

import { CONTENT } from '@src/config';
import { ITier } from '@src/ts/interfaces';
import { getRefundFee } from '@src/utils/user';

export const tierColorUI = (multiplier: number): string => {
    if (multiplier < 10) {
        return 'blue';
    }
    if (multiplier < 25) {
        return 'green';
    }
    if (multiplier < 50) {
        return 'yellow';
    }
    if (multiplier < 100) {
        return 'orange';
    }
    return 'pink';
};

export const getTierContent = (
    id: number,
    refund_amount: string,
    is_max: boolean,
    min_referrer_tier: number,
) => {
    if (is_max) {
        return [
            `${refund_amount} refund`,
            'Allocation increase proportional to the amount staked',
            'Referral program access',
            ...(CONTENT.no_governance ? [] : ['Increase voting power']),
        ];
    } else if (id >= min_referrer_tier) {
        return [
            `${refund_amount} refund`,
            'Allocation increase proportional to the amount staked',
            'Referral program access',
            ...(CONTENT.no_governance ? [] : ['Increase voting power']),
        ];
    }

    return [
        `${refund_amount} refund`,
        'Allocation increase proportional to the amount staked',
        ...(CONTENT.no_governance ? [] : ['Increase voting power']),
    ];
};

// todo: do we need this? or can we just get it from the globalcontxt?
export const getTierInfoForUpgradeDrawer = (
    tiers: ITier[],
    id: number,
    name: string,
    img: string,
    min_limit: number,
    user_power: number,
    user_actual_amount: number,
    value: number,
    fee: number,
    min_referrer_tier: number,
    setRequiredDCB: (amount: number) => void,
    setTierUpgradeStage: (stage: UpgradeTierStage) => void,
    min_tokens_for_tier_no_min: number,
) => {
    const is_max = id === tiers.length - 1;
    const multi = is_max ? value : 1;
    const amount = min_limit * multi;
    const refund_fee = getRefundFee(fee, multi);

    const diff = amount - user_power;

    // if the user is at the lowest tier, we need to include the min tokens if they have min_tokens_for_no_tier set
    const diff_including_min_tokens_for_no_tier =
        user_actual_amount < min_tokens_for_tier_no_min
            ? min_tokens_for_tier_no_min + diff
            : diff;

    return {
        tier_name: name,
        img_url: img,
        multiplier: value,
        tier_color: tierColorUI(value) as TierColors,
        is_max_tier: is_max,
        diff_to_tier: commify(diff_including_min_tokens_for_no_tier || 0),
        upgrade_refund_info_arr: getTierContent(
            id,
            `${(100 - refund_fee).toFixed(1)}%`,
            is_max,
            min_referrer_tier,
        ),

        handleClick: () => {
            setRequiredDCB(diff_including_min_tokens_for_no_tier);
            setTierUpgradeStage(UpgradeTierStage.Pools);
        },
    };
};
